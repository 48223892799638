import React, {useEffect, useState} from 'react';
import axios from "axios";
import { useRefresh, useNotify } from "react-admin";

/** ui */
import { Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

const StyledTableCell = withStyles((thm) => ({
    head: {
        backgroundColor: '#F2F4F6',
        color: '#000',
        fontWeight: 'bold',
        fontSize: '14px'
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((thm) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#fff',
        },
    },
}))(TableRow);

export const UserTable = ({initialData, ...props}) => {
    const [users, setUsers] = useState(null);
    const refresh = useRefresh();
    const notify = useNotify();

    const unattach = React.useCallback(async (userId) => {
        if(!confirm('Are you sure?')) return;

        try {
            await axios.delete(process.env.API_URL + `/packages/${initialData?.id}/users/${userId}`)
            notify('Successfully deleted!')
        } catch(e) {
            notify(e?.response?.data?.description, 'error')
        } finally {
            refresh()
        }
      }, [initialData])

    useEffect(() => {
        if(!initialData) return
        (async () => {
            const { data } = await axios.get(process.env.API_URL + '/packages/' + initialData?.id + '/users')
            setUsers(data)
        })()
    }, [initialData])

    return (
        <>
            <br/>
            <br/>
            {
                users?.length ?
                <>
                    <h2>Users</h2>
                    <TableContainer className='custom-table' component={Paper}>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>First name</StyledTableCell>
                                    <StyledTableCell>Last name</StyledTableCell>
                                    <StyledTableCell>Email</StyledTableCell>
                                    {/* --- */}
                                    <StyledTableCell>User status</StyledTableCell>
                                    <StyledTableCell>Payment status</StyledTableCell>
                                    <StyledTableCell>Company</StyledTableCell>
                                    {/* 
                                        delete icon 
                                    */}
                                    <StyledTableCell></StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {users?.map((row, ind) => {
                                    return (
                                        <StyledTableRow className='custom-style-table' key={row.id + ind}>
                                            <StyledTableCell className='custom-td' scope="row">{row.firstName}</StyledTableCell>
                                            <StyledTableCell className='custom-td' scope="row">{row.lastName}</StyledTableCell>
                                            <StyledTableCell className='custom-td'>{row.email}</StyledTableCell>
                                            {/* --- */}
                                            <StyledTableCell className='custom-td'>
                                                <span className={row.banned ? 'warning' : 'success'}>{row.banned ? 'Banned' : 'Active'}</span>
                                            </StyledTableCell>
                                            <StyledTableCell className='custom-td'>
                                                <span className={row.paid ? 'success' : 'warning'}>{row.paid ? 'Paid' : 'Unpaid'}</span>    
                                            </StyledTableCell>
                                            <StyledTableCell className='custom-td'>{row.company}</StyledTableCell>
                                            <StyledTableCell className='custom-td'>
                                                <Button onClick={() => unattach(row.id)}><DeleteIcon/></Button>    
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </> : React.Fragment
            }
            {
                (Array.isArray(users) && !users?.length) ?
                    <><h1>No attached users</h1></> : 
                    React.Fragment
            }
        </>
    )
}