/** 
 * example data provider with complex requests: 
 * https://marmelab.com/react-admin/DataProviders.html )) At the bottom
 * 
 * with interceptor and auth token */
import axios from 'axios'
import { stringify } from 'query-string';
const API_URL = process.env.API_URL;

export const statisticProvider = {
    async getList(resource, params)  {
        /** only for 1 & 3 cases */
        const { page, perPage } = params.pagination;
        const { field } = params.sort;
        let { date_from, date_to } = params.filter;

        // if(!date_from || !date_to) {
        //     /** Must check all date filters */
        //     date_from = void 0
        //     date_to = void 0
        // }

        try {
            /** Create Query Params **/
            const dateFrom = date_from ? `&dateFrom=${new Date(date_from).toISOString()}` : `&dateFrom=${new Date(0).toISOString()}`;
            const dateTo = date_to ? `&dateTo=${new Date(new Date(date_to).getTime() + 86399999).toISOString()}` : `&dateTo=${new Date().toISOString()}`;

            switch(resource) {
                case 'statistic-1': {
                    /** Create url for response **/
                    const url = `${API_URL}/user-packages/users?page=${page}&size=${perPage}&sort=${field}${dateFrom}${dateTo}`;
                    const response = await axios.get(url)
                    return { data: response.data.usersPackages.map((item, index) => ({ ...item, id: index })), total: response.data.totalItems }
                }
                case 'statistic-2': {
                    /** Create url for response **/
                    // const url = `${API_URL}/user-packages/days?${dateFrom}${dateTo}`;
                    // const response = await axios.get(url)
                    const data = []
                    // response.data.forEach((dataItem, index) => {
                    //     const packages = {}
                    //     dataItem.quantityOfPackageTypes.forEach(packageType => {
                    //         packages[packageType.type] = packageType.quantity
                    //     })
                    //     data.push({
                    //         id: index,
                    //         name: `${dataItem.date.split('-')[2]}.${dataItem.date.split('-')[1]}`,
                    //         ...packages
                    //     })
                    // })
                    // /** no paginate data */
                    return { data, total: Number.MAX_SAFE_INTEGER }
                }
                case 'statistic-3': {
                    /** Create url for response **/
                    const url = `${API_URL}/sharefile/amount/medical/bills?page=${page}&size=${perPage}${dateFrom}${dateTo}&sort=count,asc`;
                    const response = await axios.get(url)

                    return { data: response.data.caseToBillsCount.map((item, index) => ({ ...item, id: index })), total: response.data.totalItems }
                }
                case 'statistic-4': {
                    /** Create url for response **/
                    // const url = `${API_URL}/user-packages/days/revenue?${dateFrom}${dateTo}`;
                    // const response = await axios.get(url)
                    const data = []
                    // response.data.forEach((dataItem, index) => {
                    //     data.push({
                    //         id: index,
                    //         name: `${dataItem.date.split('-')[2]}.${dataItem.date.split('-')[1]}`,
                    //         value: dataItem.revenue
                    //     })
                    // })
                    /** no paginate data */
                    return { data, total: Number.MAX_SAFE_INTEGER }
                }
            }
        } catch {
            throw e
        }
    },
    async getOne(resource, params) {
        /**
         * No needs now
         */
    },
    async create(resource, params) {
        /**
         * No needs now
         */
    },
    async update(resource, params) {
        /**
         * No needs now
         */
    },
    async delete(resource, params) {
        /**
         * No needs now
         */
    },
    async getMany(resource, params) {
        /**
         * No needs now
         */
    },
    async getManyReference(resource, params) {
        /**
         * No needs now
         */
    },
    async updateMany(resource, params) {
        /**
         * No needs now
         */
    },
    async deleteMany(resource, params) {
        /**
         * No needs now
         */
    },
};