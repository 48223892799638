export function setCookie(name, value, options = {}) {
  options = {
    path: "/",
    ...options,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  options.domain = window.location.host.includes("localhost")
    ? ""
    : ".pareit.com";
  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(
    value
  )}`;

  for (const [key, val] of Object.entries(options)) {
    updatedCookie += `; ${key}=${val}`;
  }

  document.cookie = updatedCookie;
}

export function getCookie(name) {
  const cookieName = `${encodeURIComponent(name)}=`;
  const cookieArray = document.cookie.split(";");

  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(cookieName) === 0) {
      return decodeURIComponent(
        cookie.substring(cookieName.length, cookie.length)
      );
    }
  }

  return null;
}
export function clearCookie(name) {
  const pastDate = new Date(0).toUTCString();
  let cookieOptions = `expires=${pastDate}; path=/`;
  const domain = window.location.hostname.includes("localhost")
    ? "localhost"
    : ".pareit.com";
  if (domain === "localhost") {
    document.cookie = `${encodeURIComponent(name)}=; ${cookieOptions}`;
  } else {
    cookieOptions += `; domain=${domain}`;
    document.cookie = `${encodeURIComponent(name)}=; ${cookieOptions}`;
  }
}
