import React, { useEffect } from "react";
import {
  List, 
  Datagrid, 
  TextField,
  useListContext,
  TopToolbar,
  CreateButton,
  EditButton,
} from "react-admin";

import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from "@material-ui/core/styles";

const CUSTOM_TYPE = 'CUSTOM'

const useStyles = makeStyles(() => ({
  tabStyle: {
    color: 'black',
    textTransform: 'none'
  }
}));

const useTabStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  bar: {
    backgroundColor: 'white',
  },
  tabItem: {
    maxWidth: 'none',
    minWidth: 'auto',
    width: '50%'
  }
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const ListActions = (props) => {
  const { className } = props;
  const ctx = useListContext();

  return (
    <TopToolbar className={className}>
      <CreateButton className='btn' label="Create Custom Package" basePath={ctx.basePath} />
    </TopToolbar>
  );
};

export const PackageList = ({currentList, ...props}) => {
  const classes = useTabStyle();
  const { tabStyle } = useStyles();
  const [activeIndex, setActiveTab] = React.useState(0);
  const [isFirst, setFirst] = React.useState(false);

  useEffect(() => {
    /** set active tab */
    if(!isFirst) setActiveTab(+sessionStorage['packages.activeIndex'] || 0);

    /** set to storage */
    sessionStorage['packages.activeIndex'] = activeIndex;
    setFirst(true);
  }, [activeIndex])

  return (
    <div className={classes.root}>
      <AppBar className={classes.bar} position="static">
        <Tabs value={activeIndex} onChange={(e, ind) => setActiveTab(ind)}>
          <Tab className={classes.tabItem} label={<span className={tabStyle}>Default Packages (visible for all users)</span>} />
          <Tab className={classes.tabItem} label={<span className={tabStyle}>Custom Packages (assigned to specific user(s))</span>} />
        </Tabs>
      </AppBar>
      {/* 
        Tab 1
      */}
      <TabPanel value={activeIndex} index={0}>
        <List
          {...props}
          bulkActionButtons={false}
          pagination={false}
          actions={<ListActions />}
        >
          <Datagrid rowStyle={e => e.type === CUSTOM_TYPE ? { display: 'none' } : null}>
            <TextField sortable={false} label="Package type" source="type" />
            <TextField sortable={false} label="Package name" source="name" />
            <TextField sortable={false} label="Page limits" source="pageCount" />
            <TextField sortable={false} label="Cost" source="price" />
            <TextField sortable={false} label="Page price out of limit" source="additionalPagePrice" />
            <EditButton />
          </Datagrid>
        </List>
      </TabPanel>
      {/* 
        Tab 2
      */}
      <TabPanel value={activeIndex} index={1}>
        <List
            {...props}
            bulkActionButtons={false}
            pagination={false}
            filterDefaultValues={{type: [CUSTOM_TYPE]}}
            filter={{type: CUSTOM_TYPE}}
            actions={<ListActions />}
          >
            <Datagrid rowStyle={e => e.type !== CUSTOM_TYPE ? { display: 'none' } : null}>
              <TextField sortable={false} label="Package type" source="type" />
              <TextField sortable={false} label="Package name" source="name" />
              <TextField sortable={false} label="Page limits" source="pageCount" />
              <TextField sortable={false} label="Cost" source="price" />
              <TextField sortable={false} label="Page price out of limit" source="additionalPagePrice" />
              <EditButton />
            </Datagrid>
        </List>
      </TabPanel>
    </div>
  );
}

