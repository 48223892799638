import * as React from "react";
import { forwardRef, useContext, useEffect, useState } from "react";

/** React Admin Components **/
import { AppBar, UserMenu, MenuItemLink, useTranslate } from "react-admin";

/** React Materialize UI **/
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import axios from "axios";
import Logo from "../../logo.png";
import defaultAvatar from "../../avatar.png";
import NotifyCtx from "../../notifyCtx";
import { JWT_TOKEN, PAREIT_AUTH_SERVICE, SUB } from "../../constants";
import jwtDecode from "jwt-decode";

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
  avatar: {
    height: 30,
    width: 30,
  },
  name: {
    color: "#000000",
    letterSpacing: "0.25px",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
    marginLeft: "10px",
  },
  userMenu: {
    background: "#000",
  },
});
const API_URL = process.env.API_URL;
export const MyAppBar = (props) => {
  const [avatar, setAvatar] = useState(null);
  const { user } = useContext(NotifyCtx) || {};
  const userId = sessionStorage.getItem(JWT_TOKEN)
    ? jwtDecode(JSON.parse(sessionStorage.getItem(JWT_TOKEN)))[SUB]
    : "";
  useEffect(() => {
    if (avatar === null) {
      axios
        .get(`${API_URL}/v1/users/${userId}/avatar`, {
          headers: {
            "dapr-app-id": PAREIT_AUTH_SERVICE,
          },
        })
        .then((res) => {
          setAvatar(`data:image;base64,${res?.data?.image}`);
        })
        .catch(() => {
          setAvatar("no-avatar");
        });
    }
  }, []);

  const ConfigurationMenu = forwardRef((props, ref) => {
    return (
      <MenuItemLink
        ref={ref}
        to="/profile"
        primaryText="My Profile"
        leftIcon={<AccountCircleIcon />}
        onClick={props.onClick}
        sidebarIsOpen
      />
    );
  });

  const MyUserMenu = (props) => {
    return (
      <UserMenu className="user-menu" icon={<MyCustomIcon />} {...props}>
        <ConfigurationMenu />
      </UserMenu>
    );
  };

  const MyCustomIcon = () => {
    const classes = useStyles();
    return (
      <>
        <Avatar
          className={classes.avatar}
          src={avatar === "no-avatar" ? defaultAvatar : avatar}
        />
        <p className={classes.name}>
          {user?.first_name} {user?.last_name}
        </p>
      </>
    );
  };

  const classes = useStyles();
  return (
    <AppBar userMenu={<MyUserMenu />} className="appbar-user-info" {...props}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <img src={Logo} alt="logo" />
      <span className="transparens"></span>
      <span className={classes.spacer} />
    </AppBar>
  );
};
