import React, { useEffect, useState } from "react";
import {
  List,
  Filter,
  DateInput,
  useListContext,
} from "react-admin";

import { useMeasure } from "react-use";
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import axios from 'axios'

const Graph = (props) => {
  const [graphData, setgraphData] = useState([]);
  const API_URL = process.env.API_URL;
  const axisNames = {
    y: 'Revenue',
    x: 'Date'
  }

  useEffect(async() => {
    const { filterValues:{date_from, date_to} } = props;

    const dateFrom = date_from ? `&dateFrom=${new Date(date_from).toISOString()}` : `&dateFrom=${new Date(new Date().getTime() - 2592000000).toISOString()}`;
    const dateTo = date_to ? `&dateTo=${new Date(new Date(date_to).getTime() + 86399999).toISOString()}` : `&dateTo=${new Date().toISOString()}`;
   
    try {
      /** Create url for response **/
      const url = `${API_URL}/user-packages/days/revenue?${dateFrom}${dateTo}`;
      const response = await axios.get(url)
      const data = []
      response.data.forEach((dataItem, index) => {
          data.push({
              id: index,
              name: `${dataItem.date.split('-')[2]}.${dataItem.date.split('-')[1]}`,
              value: dataItem.revenue
          })
      })
     setgraphData(data)     
    } catch(e) {
      throw e
    }
  }, [props.filterValues])
  
  const [containerRef, { width }] = useMeasure();
  // let { data } = useListContext();

  return (<div ref={containerRef}>
      <br />
      <b>{ axisNames.y }<br/></b>
      <br/>
      {width && <><BarChart
        width={width}
        height={650}
        data={Object.values(graphData)}
        margin={{ top: 5, right: 30, left: 20, bottom: 25 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend wrapperStyle={{
            paddingTop: "40px"
        }}/>
        <Bar barSize="3" dataKey="value" fill="#E9C1AF" />
      </BarChart>
      <div style={{ textAlign: 'right', marginTop: '-70px' }}>
        <b>{ axisNames.x }<br/></b>
      </div>
    </>
    }
  </div>);
}

const ListActions = (props) =>  (
  <Filter className='custom-filters' {...props}>
    <DateInput className='custom-filters__custom-date-input' alwaysOn source="date_from" />
    <DateInput className='custom-filters__custom-date-input' alwaysOn source="date_to" />
  </Filter>
);

export const Statistic_4 = ({ ...props }) => {
  return (
    <>
      <br/>
      <div className="admin-user-info">
          <h2>Packages / Revenue</h2>
      </div>
      <br/>
      <List
          {...props}
          pagination={false}
          component="div"
          filters={<ListActions />}
      >
          <Graph />
      </List>
    </>
  );
}