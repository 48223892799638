import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  SearchInput,
  DateField,
  Filter,
} from "react-admin";
import avatar from "../../../avatar.png";
import {
  ACTIVE,
  IMPERSONATE_USER,
  INACTIVE,
  JWT_TOKEN,
  PENDING_VERIFICATION,
} from "../../../constants";
import { setCookie } from "../../../utils/CookieUtils";

const TagFilter = (props) => (
  <Filter className="custom-filters" {...props}>
    <SearchInput
      className="custom-filters__search-input"
      source="q"
      resettable
      alwaysOn
    />
  </Filter>
);

const StatusInput = ({ record = {}, source }) => {
  switch (record[source]) {
    case ACTIVE:
      return <span className="text active status-field">Active</span>;
    case INACTIVE:
      return <span className="text inactive status-field">Inactive</span>;
    case PENDING_VERIFICATION:
      return (
        <span className="text pending-verification status-field">
          Pending verification
        </span>
      );
    default:
      return <span className="text inactive status-field">Inactive</span>;
  }
};

const ReasonInput = ({ record = {}, source }) => {
  const getReason = (reason) =>
    reason
      .replaceAll("_", " ")
      .split(" ")
      .map((i, index) =>
        index === 0 ? i[0].toUpperCase() + i.slice(1) : i.toLowerCase()
      )
      .join(" ");

  return (
    <span>
      {record[source] === (null || "" || undefined)
        ? "-"
        : getReason(record[source])}
    </span>
  );
};

const CustomFieldImages = ({ record = {}, source }) => {
  return (
    <img
      style={{
        width: "25px",
        height: "25px",
        borderRadius: "100%",
        objectFit: "cover",
      }}
      src={record[source] ? `data:image;base64,${record[source]}` : avatar}
      alt="title"
    />
  );
};
const handleRedirect = (record) => {
  setCookie("access_token", JSON.parse(sessionStorage.getItem(JWT_TOKEN)), {});
  const redirectUrl = `${
    process.env.APP_URL
  }/impersonate-user?email=${encodeURIComponent(record?.contact?.email)}`;
  window.open(redirectUrl, "_blank");
};
const AccessButton = ({ record = {} }) => {
  if (record?.status !== PENDING_VERIFICATION) {
    return (
      <button
        id={record?.id}
        className="btn"
        onClick={() => handleRedirect(record)}
      >
        {IMPERSONATE_USER}
      </button>
    );
  } else {
    return <span>-</span>;
  }
};

export const SuperAdminList = (props) => {
  return (
    <List
      {...props}
      className="custom-table-list"
      sort={{ field: "first_name", order: "desc" }}
      filters={<TagFilter />}
    >
      <Datagrid>
        <CustomFieldImages sortable={false} source="avatar" />
        <TextField className="text name" source="first_name" label="Name" />
        <TextField className="text name" source="last_name" />
        <StatusInput source="status" />
        <ReasonInput source="reason" />
        <EmailField className="text" source="contact.email" label="Email" />
        <EmailField className="text" source="contact.phone" label="Phone" />
        <TextField className="text" source="company_name" label="Company" />
        <DateField className="text" source="created_at" label="Created date" />
        <AccessButton sortable={false} source="access" />
      </Datagrid>
    </List>
  );
};
