import React, {useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


export const AlertDialog = ({isOpen, closeModal, type, deleteUser}) => {
    const [open, setOpen] = React.useState(isOpen);

    useEffect(() => {
        setOpen(isOpen)
    }, [isOpen])

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <div>
            <Dialog
                open={open}
                onExit={closeModal}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{type.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {type.description}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}
                            color="primary"
                            variant="outlined"
                            autoFocus>
                        Cancel
                    </Button>
                    {
                        type.type === 'delete' ?
                            ( <Button  variant="outlined"
                                       color="primary"
                                       className='outlive-delete'
                                       onClick={() => deleteUser()}>
                            Delete
                        </Button>) : ('')
                    }
                </DialogActions>
            </Dialog>
        </div>
    );
}