import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { stringify } from 'query-string';
import axios from "axios";
import produce from "immer";
import { useRefresh, useNotify } from 'react-admin';

/** ui */
import { Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/Input';
import Pagination from '@material-ui/lab/Pagination';
import { withStyles } from '@material-ui/core/styles';

/** */
import CloseIcon from '@material-ui/icons/Close';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxIcon2 from '@material-ui/icons/CheckBoxOutlineBlankSharp';
import CheckIcon from '@material-ui/icons/Check';
import debounce from 'lodash/debounce';

const PER_PAGE = 6

const StyledTableCell = withStyles((thm) => ({
  head: {
      backgroundColor: '#F2F4F6',
      color: '#000',
      fontWeight: 'bold',
      fontSize: '14px'
  },
  body: {
      fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((thm) => ({
  root: {
      '&:nth-of-type(odd)': {
          backgroundColor: '#fff',
      },
  },
}))(TableRow);

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: `80%`,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export const BodyTable = ({initialData, emitClose, ...props}) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const notify = useNotify();
  const refresh = useRefresh();

  /** user that alredy attached */
  const [users, setUsers] = React.useState(null);

  /** main data */
  const [currentPag, setPag] = React.useState(1);
  const [metaData, setMeta] = React.useState(null);
  const [checked, setChecked] = React.useState({});
  const [allUsers, setAllUsers] = React.useState(null);

  /** search data */
  const [searchValue, setSearchValue] = React.useState('');

  const add = React.useCallback((row) => {
    setChecked((produce(checked, (draftState) => {
      if(draftState[row.id]) {
        delete draftState[row.id]
      } else {
        draftState[row.id] = row
      }
    })));
  }, [checked]);

  const fetch = React.useCallback(async ({ page, search } = {}) => {
    page = page || currentPag;
    search = search ?? searchValue;

    const q = stringify({ role: 'User', page, search, size: PER_PAGE });
    const { data } = await axios.get(process.env.API_URL + `/user/all?${q}`);

    /** Save data */
    setPag(page);
    setSearchValue(search);
  
    /** Data set */
    setAllUsers(data.users);
    setMeta({ ...data });
  }, [searchValue, currentPag]);

  /** 
   * Initial !!!!
   * Get all users, initially */
  React.useEffect(fetch, [])

  /** Attach user */
  const attach = React.useCallback(async (id) => {
    if (Object.keys(checked).length !== 0) {
      try {
        await axios.post(process.env.API_URL + `/packages/${initialData?.id}/users/`, {
          userIds: Object.keys(checked)
        })
        notify('Successfully added!')
      } catch(e) {
        notify(e.response.data.description, 'warning')
      } finally {
        emitClose?.()
        refresh()
      }
    } else {
      notify('Select user(s)', 'warning')
    }
  }, [checked])

  /** 
   * Fetch users for check exists attaching 
   * */
  React.useEffect(() => {
    if(!initialData) return
    (async () => {
        const { data } = await axios.get(process.env.API_URL + '/packages/' + initialData?.id + '/users')
        setUsers(data)
    })()
  }, [initialData])

  return (
    <div style={modalStyle} className={classes.paper}>
      <br/>
      <h2>Users</h2>
      <br/>
      <Input
        onChange={debounce((e) => fetch({ search: e.target.value, page: 1 }), 500)}
        placeholder="Search"
      />
      <ul class="user-list">
        {
          Object.values(checked).map(i => <li>
            <Button onClick={() => add(i)}><CloseIcon/> {i.name}</Button>
          </li>)
        }
      </ul>
      <br/>
      <TableContainer className='custom-table' component={Paper}>
          <Table aria-label="customized table">
              <TableHead>
                  <TableRow>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell>First name</StyledTableCell>
                      <StyledTableCell>Last name</StyledTableCell>
                      <StyledTableCell>Email</StyledTableCell>
                      {/* --- */}
                      <StyledTableCell>User status</StyledTableCell>
                      <StyledTableCell>Payment status</StyledTableCell>
                      <StyledTableCell>Company</StyledTableCell>
                  </TableRow>
              </TableHead>
              <TableBody>
                  {allUsers?.map((row) => {
                      return (
                          <StyledTableRow className='custom-style-table' key={row.id}>
                              <StyledTableCell className='custom-td'>
                                { !users?.find(i => i.id === row.id) ? 
                                  <Button onClick={() => add(row)}>{checked[row.id] ? <CheckBoxIcon/> : <CheckBoxIcon2/> }</Button> :
                                  <Button disabled><CheckIcon style={{ fill: '#4CAF50' }}/></Button>
                                }
                              </StyledTableCell>
                              <StyledTableCell className='custom-td' scope="row">{row.name}</StyledTableCell>
                              <StyledTableCell className='custom-td' scope="row">{row.lastName}</StyledTableCell>
                              <StyledTableCell className='custom-td'>{row.email}</StyledTableCell>
                              {/* --- */}
                              <StyledTableCell className='custom-td'>
                                  <span className={row.banned ? 'warning' : 'success'}>{row.banned ? 'Banned' : 'Active'}</span>
                              </StyledTableCell>
                              <StyledTableCell className='custom-td'>
                                  <span className={row.paid ? 'success' : 'warning'}>{row.paid ? 'Paid' : 'Unpaid'}</span>    
                              </StyledTableCell>
                              <StyledTableCell className='custom-td'>{row.company}</StyledTableCell>
                          </StyledTableRow>
                      )
                  })}
              </TableBody>
          </Table>
      </TableContainer>
      <br/>
      <Pagination page={currentPag} count={metaData?.totalPages} onChange={(_, pageCount) => fetch({ page: pageCount })}/>
      <br/>
      <Button class="btn pull-right" onClick={() => attach()}>&nbsp;&nbsp;&nbsp;&nbsp;Save&nbsp;&nbsp;&nbsp;&nbsp;</Button>
    </div>
  );
}

export const UsersModal = ({initialData, ...props}) => {
  return (
    <div>
      <Modal
        {...props}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {props.open && <BodyTable initialData={initialData} {...props}/>}
      </Modal>
    </div>
  );
}