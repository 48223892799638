import PhoneInput from "react-phone-number-input";

const PhoneNumberInput = ({
  handlePhoneNumberBlur,
  handlePhoneNumberChange,
  phoneNumber,
  phoneError,
}) => {
  return (
    <>
      <PhoneInput
        international
        placeholder="000 000 000"
        name="phone"
        countryCallingCodeEditable={false}
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
        onBlur={handlePhoneNumberBlur}
        defaultCountry="US"
        countries={["CA", "IN", "UA", "US"]}
        className={phoneError ? "PhoneInput-error" : ""}
      />

      {!!phoneError && <span className="phone-number-error">{phoneError}</span>}
    </>
  );
};
export default PhoneNumberInput;
