import * as React from "react";
import { useEffect } from "react";
import { Admin, Resource } from "react-admin";
import authProvider from "./provider/authProvider";
import { MyLayout } from "./components/global/Layout";
import TreeMenu from "@bb-tech/ra-treemenu";
import CustomRoute from "./components/global/CustomRoute";

import { dataProvider } from "./provider/dataProvider";

import { UserList } from "./components/users/user/UserList";
import { UserCreate } from "./components/users/user/CreateUser";
import { UserEdit } from "./components/users/user/EditUser";

import { AdminList } from "./components/users/admin/AdminList";
import { AdminCreate } from "./components/users/admin/CreateAdmin";
import { AdminEdit } from "./components/users/admin/EditAdmin";

import { SuperAdminList } from "./components/users/super-admin/SuperAdminList";

import { PackageList } from "./components/package/PackageList";
import { PackageEdit } from "./components/package/PackageEdit";
import { PackageCreate } from "./components/package/PackageCreate";

import { RequestsList } from "./components/requests/RequestsList";

import { Statistic_1 } from "./components/statistic/1-Statistic";
import { Statistic_2 } from "./components/statistic/2-Statistic";
import { Statistic_3 } from "./components/statistic/3-Statistic";
import { Statistic_4 } from "./components/statistic/4-Statistic";

import { MyLoginPage } from "./pages/Login/MyLoginPage";

import { createBrowserHistory as createHistory } from "history";
import axiosConfig from "./axios/axiosConfig";
import Context from "./notifyCtx";
import axios from "axios";
import { setAutoFreeze } from "immer";
import jwtDecode from "jwt-decode";
import { JWT_TOKEN, PAREIT_AUTH_SERVICE, SUB } from "./constants";

const history = createHistory();
setAutoFreeze(false);
axiosConfig();
const API_URL = process.env.API_URL;
const App = () => {
  const [user, setUser] = React.useState({});
  useEffect(() => {
    const userId = sessionStorage.getItem(JWT_TOKEN)
      ? jwtDecode(JSON.parse(sessionStorage.getItem(JWT_TOKEN)))[SUB]
      : "";
    if (sessionStorage.getItem(JWT_TOKEN)) {
      axios
        .get(`${API_URL}/v1/users/${userId}`, {
          headers: {
            "dapr-app-id": PAREIT_AUTH_SERVICE,
          },
        })
        .then((res) => {
          setUser(res.data);
        })
        .catch(() => {});
    }
  }, []);

  return (
    <Context.Provider value={{ user, setUser }}>
      <Admin
        layout={MyLayout}
        loginPage={MyLoginPage}
        dataProvider={dataProvider}
        customRoutes={CustomRoute}
        authProvider={authProvider}
        menu={TreeMenu}
        history={history}
      >
        {(permissions) => [
          <Resource
            options={{ label: "Users" }}
            name="user"
            create={UserCreate}
            edit={UserEdit}
            list={UserList}
          />,
          <Resource
            options={{ label: "Requests" }}
            name="requests"
            list={RequestsList}
          />,
          permissions === "SuperAdmin"
            ? [
                <Resource
                  options={{ label: "SuperAdmin" }}
                  name="super_admin"
                  list={SuperAdminList}
                />,
                <Resource
                  options={{ label: "Admin" }}
                  name="admin"
                  create={AdminCreate}
                  edit={AdminEdit}
                  list={AdminList}
                />,
                <Resource
                  options={{ label: "Packages" }}
                  name="packages"
                  create={PackageCreate}
                  edit={PackageEdit}
                  list={PackageList}
                />,
              ]
            : null,

          [
            <Resource
              options={{ label: "Packages / Users" }}
              name="statistic-1"
              list={Statistic_1}
            />,
            <Resource
              options={{ label: "Packages / $" }}
              name="statistic-2"
              list={Statistic_2}
            />,
            <Resource
              options={{ label: "Bills / Case" }}
              name="statistic-3"
              list={Statistic_3}
            />,
            <Resource
              options={{ label: "Packages revenue" }}
              name="statistic-4"
              list={Statistic_4}
            />,
          ],
        ]}
      </Admin>
    </Context.Provider>
  );
};

export default App;
