import React from "react";
import {
  List, 
  Datagrid,
  Filter,
  SearchInput,
  DateInput,
  ChipField,
  TextField,
  useRedirect
} from "react-admin";

const ListActions = (props) =>  (
  <Filter className='custom-filters' {...props}>
    <SearchInput className='custom-filters__search-input' source="q" resettable alwaysOn />
    <DateInput className='custom-filters__custom-date-input' alwaysOn source="date_from" />
    <DateInput className='custom-filters__custom-date-input' alwaysOn source="date_to" />
  </Filter>
);

export const Statistic_1 = ({ ...props }) => {
  const redirect = useRedirect();

  return (
    <>
      <br/>
      <div className="admin-user-info">
          <h2>Packages / Users</h2>
      </div>
      <br/>
      <List
        {...props}
        bulkActionButtons={false}
        component="div"
        filters={<ListActions/>}
      >
        <Datagrid rowClick={(x, y, record) => {
          redirect(`/user/${record?.userId}`)
        }}>
          <TextField sortable={false} label="User name" source="name" />
          <TextField sortable={false} label="User email" source="email" />
          <ChipField sortable={false} label="Packages Count" source="packageCount" />
        </Datagrid>
      </List>
    </>
  );
}

