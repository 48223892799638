import React, { useContext, useEffect, useState } from "react";
import { useNotify } from "react-admin";

import Button from "@material-ui/core/Button";

import axios from "axios";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import jwtDecode from "jwt-decode";
import {
  JWT_TOKEN,
  PAREIT_AUTH_SERVICE,
  PROFILE_UPDATED,
  SAVE_EDIT,
  SOMETHING_WENT_WRONG,
  SUB,
} from "../../constants";
import NotifyCtx from "../../notifyCtx";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
const API_URL = process.env.API_URL;
export const MyProfile = () => {
  const notify = useNotify();
  const { user, setUser } = useContext(NotifyCtx) || {};
  const [isDisabled, setIsDisabled] = useState(false);
  const userId = sessionStorage.getItem(JWT_TOKEN)
    ? jwtDecode(JSON.parse(sessionStorage.getItem(JWT_TOKEN)))[SUB]
    : "";
  useEffect(() => {
    setIsDisabled(true);
    axios
      .get(`${API_URL}/v1/users/${userId}`, {
        headers: {
          "dapr-app-id": PAREIT_AUTH_SERVICE,
        },
      })
      .then((res) => {
        setUser(res.data);
        setIsDisabled(false);
      })
      .catch(() => setIsDisabled(false));
  }, []);

  const editPersonalData = (e) => {
    e.preventDefault();
    setIsDisabled(true);
    axios
      .put(`${API_URL}/v1/users/${userId}`, user, {
        headers: {
          "dapr-app-id": PAREIT_AUTH_SERVICE,
        },
      })
      .then(() => {
        notify(PROFILE_UPDATED);
        setIsDisabled(false);
      })
      .catch(() => {
        notify(SOMETHING_WENT_WRONG, "error");
        setIsDisabled(false);
      });
  };

  return (
    <div className="profile-page">
      <h1>Main Information</h1>
      <ValidatorForm
        onError={(errors) => console.log(errors)}
        onSubmit={editPersonalData}
      >
        <div className="form-data">
          <TextValidator
            className="custom-input"
            value={user?.first_name}
            placeholder="First name"
            name="first_name"
            defaultValue=""
            validators={["required"]}
            errorMessages={["This field is required"]}
            onChange={(e) => setUser({ ...user, first_name: e.target.value })}
            type="text"
            disabled={isDisabled}
          />
          <TextValidator
            className="custom-input"
            value={user?.last_name}
            placeholder="Last name"
            name="last_name"
            defaultValue=""
            validators={["required"]}
            errorMessages={["This field is required"]}
            onChange={(e) => setUser({ ...user, last_name: e.target.value })}
            type="text"
            disabled={isDisabled}
          />
          <TextValidator
            className="custom-input"
            value={user?.contact?.email}
            placeholder="Email"
            name="email"
            defaultValue=""
            validators={["required", "isEmail"]}
            errorMessages={["This field is required", "Email is not valid"]}
            onChange={(e) =>
              setUser({
                ...user,
                contact: { ...user.contact, email: e.target.value },
              })
            }
            type="text"
            disabled
          />
          <TextValidator
            className="custom-input"
            placeholder="Company name"
            value={user?.company_name}
            name="company_name"
            validators={["required"]}
            errorMessages={["This field is required"]}
            onChange={(e) => setUser({ ...user, company_name: e.target.value })}
            type="text"
            disabled={isDisabled}
          />
          <PhoneInput value={user?.contact?.phone} disabled />
        </div>
        <Button
          type="submit"
          className="btn"
          variant="contained"
          color="primary"
        >
          {SAVE_EDIT}
        </Button>
      </ValidatorForm>
    </div>
  );
};
