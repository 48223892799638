/**
 * example data provider with complex requests:
 * https://marmelab.com/react-admin/DataProviders.html )) At the bottom
 */

import { stringify } from "query-string";
import { packageProvider } from "./packageProvider";
import { requestsProvider } from "./requestsProvider";
import { statisticProvider } from "./statisticProvider";

/**
 * User Provider
 */
import { userProvider } from "./userProvider";

const allResources = {
  USERS: "user",
  ADMIN: "admin",
  SUPER_ADMIN: "super_admin",
  PACKAGES: "packages",
  REQUESTS: "requests",
  STATISTIC: "statistic",
};

export const dataProvider = {
  async getList(resource, params) {
    /**
     * can use all filters
     * const { page, perPage } = params.pagination;
     * const { field, order } = params.sort;
     */
    try {
      if (resource?.startsWith(allResources.STATISTIC)) {
        return await statisticProvider.getList.apply(
          statisticProvider,
          arguments
        );
      }

      switch (resource) {
        case allResources.PACKAGES:
          return await packageProvider.getList.apply(
            packageProvider,
            arguments
          );
        case allResources.USERS:
        case allResources.ADMIN:
        case allResources.SUPER_ADMIN:
          return await userProvider.getList.apply(userProvider, arguments);
        case allResources.REQUESTS:
          return await requestsProvider.getList.apply(
            requestsProvider,
            arguments
          );
      }
    } catch {
      /** for test */
      return { data: [], total: 0 };
    }
  },
  async getOne(resource) {
    try {
      switch (resource) {
        case allResources.PACKAGES:
          return await packageProvider.getOne.apply(packageProvider, arguments);
        case allResources.USERS:
        case allResources.ADMIN:
        case allResources.SUPER_ADMIN:
          return await userProvider.getOne.apply(userProvider, arguments);
        case allResources.REQUESTS:
          return await requestsProvider.getOne.apply(
            requestsProvider,
            arguments
          );
      }
    } catch {
      return { data: null };
    }
  },
  async update(resource, params) {
    try {
      switch (resource) {
        case allResources.PACKAGES:
          return await packageProvider.update.apply(packageProvider, arguments);
        case allResources.USERS:
        case allResources.ADMIN:
        case allResources.SUPER_ADMIN:
          return await userProvider.update.apply(userProvider, arguments);
      }
    } catch {
      return { data: null };
    }
  },
  async create(resource, params) {
    try {
      switch (resource) {
        case allResources.PACKAGES:
          return await packageProvider.create.apply(packageProvider, arguments);
        case allResources.USERS:
        case allResources.ADMIN:
        case allResources.SUPER_ADMIN:
          return await userProvider.create.apply(userProvider, arguments);
      }
    } catch {
      return { data: null };
    }
  },
  async delete(resource, params) {
    try {
      switch (resource) {
        case allResources.PACKAGES:
          return await packageProvider.delete.apply(packageProvider, arguments);
        case allResources.USERS:
        case allResources.ADMIN:
        case allResources.SUPER_ADMIN:
          return await userProvider.delete.apply(userProvider, arguments);
      }
    } catch {
      return { data: null };
    }
  },
};
