import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  Toolbar,
  ListButton,
  email,
  required,
  SaveButton,
  useNotify,
  FormDataConsumer,
  useRedirect,
} from "react-admin";
import { dataProvider } from "../../../provider/dataProvider";
import { includeNumber, includeSpace } from "../../../validators";
import "react-phone-number-input/style.css";
import {
  INVALID_PHONE_NUMBER,
  REQUIRED,
  SOMETHING_WENT_WRONG,
  USER,
  USER_ADDED,
} from "../../../constants";
import {
  formatPhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import PhoneNumberInput from "../../global/PhoneNumberInput";

export const UserCreate = (props) => {
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [phoneError, setPhoneError] = React.useState("");
  const notify = useNotify();
  const redirect = useRedirect();

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
    setPhoneError("");
  };

  const handlePhoneNumberBlur = () => {
    if (formatPhoneNumber(phoneNumber).trim() === "") {
      setPhoneError(REQUIRED);
    }
    if (
      formatPhoneNumber(phoneNumber).trim() !== "" &&
      !isValidPhoneNumber(phoneNumber)
    ) {
      setPhoneError(INVALID_PHONE_NUMBER);
    }
  };

  const handleSubmit = async (formData) => {
    try {
      const data = { ...formData, phone: phoneNumber };
      if (
        phoneNumber &&
        formatPhoneNumber(phoneNumber).trim() !== "" &&
        isValidPhoneNumber(phoneNumber)
      ) {
        const response = await dataProvider.create(USER, data);
        if (!response.data) {
          throw { message: SOMETHING_WENT_WRONG };
        }
        notify(USER_ADDED);
        redirect(`/${USER}/${response?.data?.id}`);
      }
    } catch (error) {
      notify(error.message || SOMETHING_WENT_WRONG, "error");
    }
  };

  const handleClick = (e) => {
    if (formatPhoneNumber(phoneNumber).trim() === "") {
      e.preventDefault();
      setPhoneError(REQUIRED);
    }
  };
  const MyToolBar = (props) => (
    <Toolbar className="custom-toolbar" {...props}>
      <SaveButton
        onSave={handleSubmit}
        className="btn"
        label="Create User"
        redirect="show"
        onClick={handleClick}
        submitOnEnter
      />
      <ListButton icon={null} label="Back" className="pull-right" />
    </Toolbar>
  );
  return (
    <Create {...props}>
      <SimpleForm className="custom-form" toolbar={<MyToolBar />}>
        <FormDataConsumer>
          {({ formData }) => {
            return (
              <>
                <h2>Create user</h2>
                <div className="form-group">
                  <TextInput
                    validate={[required(), includeNumber(), includeSpace()]}
                    className="custom-input"
                    source="first_name"
                  />
                  <TextInput
                    validate={[required(), includeNumber(), includeSpace()]}
                    className="custom-input"
                    source="last_name"
                  />
                  <TextInput
                    validate={[required(), email()]}
                    className="custom-input"
                    source="email"
                  />
                  <TextInput
                    validate={required()}
                    className="custom-input"
                    source="company"
                  />
                  <PhoneNumberInput
                    handlePhoneNumberBlur={handlePhoneNumberBlur}
                    handlePhoneNumberChange={handlePhoneNumberChange}
                    phoneNumber={phoneNumber}
                    phoneError={phoneError}
                  />
                </div>
              </>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};
