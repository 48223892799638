import { Layout } from 'react-admin';
import { MyAppBar } from './AppBar';
import { MyMenu } from './Menu';

const myTheme = {
  sidebar: {
    width: 255, 
    closedWidth: 0, 
  },
}

export const MyLayout = (props) => (
  <Layout {...props} theme={myTheme} menu={MyMenu} appBar={MyAppBar} />
);
