import * as React from "react";
import { useState } from "react";

import { useLogin } from "react-admin";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";

import axios from "axios";
import { encode } from "js-base64";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import { Preloader } from "../../components/global/Preloader";
import {
  INVALID_EMAIL_OR_PASSWORD,
  PAREIT_AUTH_SERVICE,
  SECOND,
  SECONDS,
  SOMETHING_WENT_WRONG,
} from "../../constants";
import { CustomSnackbar } from "../../components/global/Snackbar";
const API_URL = process.env.API_URL;
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
    position: "relative",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    span: {
      color: "#fff",
    },
  },
}));

export const MyLoginPage = () => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [smsCode, setSmsCode] = useState("");
  const [stateToken, setStateToken] = useState("");
  const [error, setError] = useState("");
  const [time, setTime] = useState(60);
  const [preloader, setPreloader] = useState(false);
  const login = useLogin();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [typeSnackbar, setTypeSnackbar] = useState({
    title: SOMETHING_WENT_WRONG,
    severity: "error",
  });
  const submit = (e) => {
    e.preventDefault();
    login({ stateToken, smsCode }).catch((e) => {
      setTypeSnackbar({
        title: e.message || e.response.data.message,
        severity: "error",
      });
      setOpenSnackbar(false);
      setOpenSnackbar(true);
    });
  };
  const sendOTP = (e) => {
    e.preventDefault();
    setPreloader(true);
    axios
      .post(
        `${API_URL}/v1/auth/send-otp`,
        {
          communication_channel: ["sms"],
          state_token: stateToken,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "dapr-app-id": PAREIT_AUTH_SERVICE,
          },
        }
      )
      .then(() => {
        setPreloader(false);
      })
      .catch((e) => {
        setTypeSnackbar({
          title: e.message || e.response.data.message,
          severity: "error",
        });
        setOpenSnackbar(false);
        setOpenSnackbar(true);
        setPreloader(false);
      });
  };
  const loginFirstStep = (e) => {
    e.preventDefault();
    setPreloader(true);
    const BASIC_USER_CRED = `${email}:${password}`;

    axios
      .post(`${API_URL}/v1/auth/admin-login`, null, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${encode(BASIC_USER_CRED)}`,
          "dapr-app-id": PAREIT_AUTH_SERVICE,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setPreloader(false);
          setStateToken(res.data.state_token);
          setTime(60);
          let newTime = 60;
          const interval = setInterval(() => {
            setTime((prevState) => prevState - 1);
            newTime--;
            if (newTime === 0) {
              clearInterval(interval);
            }
          }, 1000);
        }
      })
      .catch((e) => {
        setPreloader(false);
        if (e?.response?.status === 401) {
          setError(INVALID_EMAIL_OR_PASSWORD);
        } else {
          setError(e?.response?.data?.message || e.message);
        }
      });
  };

  const changeHandler = (e) => {
    let str = e.target.value.replace(/[A-zА-яЁё]+$/, "");
    setSmsCode(str);
  };

  const layoutFirstStep = () => {
    return (
      <ValidatorForm
        onError={(errors) => console.log(errors)}
        onSubmit={loginFirstStep}
        className={classes.form}
      >
        {preloader ? <Preloader /> : ""}
        <TextValidator
          variant="outlined"
          margin="normal"
          fullWidth
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          label="Email Address"
          validators={["required", "isEmail"]}
          errorMessages={["This field is required", "Email is not valid"]}
          name="email"
          autoComplete="email"
          autoFocus
        />
        <TextValidator
          variant="outlined"
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          validators={["required"]}
          errorMessages={["This field is required"]}
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
        />
        {error ? (
          <Alert variant="filled" severity="error">
            {error}
          </Alert>
        ) : (
          ""
        )}
        <br />
        <Button className="btn" type="submit" fullWidth>
          Sign In
        </Button>
      </ValidatorForm>
    );
  };

  const verificationLayout = () => {
    const secondWord = time !== 1 ? SECONDS : SECOND;
    return (
      <ValidatorForm
        onSubmit={submit}
        onError={(errors) => console.log(errors)}
        className={classes.form}
      >
        {preloader ? <Preloader /> : ""}
        <p className="description">
          A verification SMS has been sent to your phone. The code is valid for
          30 minutes
        </p>
        <TextValidator
          variant="outlined"
          margin="normal"
          fullWidth
          validators={["required", "maxStringLength:6"]}
          errorMessages={["This field is required", "Max number must be 6"]}
          id="code"
          value={smsCode}
          onChange={(e) => changeHandler(e)}
          label="Code"
          name="code"
          autoComplete="code"
          autoFocus
        />
        <p className="code-send">
          {time !== 0 ? "Code Sent" : "Didn't receive the code?"}
        </p>
        <p className="code-send">
          {time !== 0 ? (
            `Will be able to send again in ${time} ${secondWord}`
          ) : (
            <>
              <br />
              <Button onClick={(e) => sendOTP(e)} className="btn">
                Send again
              </Button>
            </>
          )}
        </p>
        <br />
        <Button className="btn" type="submit" fullWidth>
          Sign In
        </Button>
      </ValidatorForm>
    );
  };

  return (
    <>
      <CustomSnackbar type={typeSnackbar} openSnackBar={openSnackbar} />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          {!stateToken ? layoutFirstStep() : verificationLayout()}
        </div>
      </Container>
    </>
  );
};
