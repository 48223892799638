import React from "react";
import {
  List, 
  Filter,
  Datagrid,
  SearchInput,
  ChipField,
  DateInput,
  TextField,
  useRedirect
} from "react-admin";

const ListActions = (props) =>  (
  <Filter className='custom-filters' {...props}>
    <SearchInput className='custom-filters__search-input' source="q" resettable alwaysOn />
    <DateInput className='custom-filters__custom-date-input' alwaysOn source="date_from" />
    <DateInput className='custom-filters__custom-date-input' alwaysOn source="date_to" />
  </Filter>
);

export const Statistic_3 = ({ ...props }) => {
  /** no need now */
  const redirect = useRedirect();

  return (
    <>
      <br/>
      <div className="admin-user-info">
          <h2>Bills / Case</h2>
      </div>
      <br/>
      <List
        {...props}
        bulkActionButtons={false}
        component="div"
        filters={<ListActions/>}
      >
        <Datagrid rowClick={(x, y, record) => {
          // redirect(`/user/${record?.user.id}`)
        }}>
          <TextField sortable={false} label="Case name" source="name" />
          {/* <TextField sortable={false} label="Case description" source="case.desc" /> */}
          <ChipField sortable={false} label="Bills Count" source="count" />
        </Datagrid>
      </List>
    </>
  );
}