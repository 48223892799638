/** 
 * example data provider with complex requests: 
 * https://marmelab.com/react-admin/DataProviders.html )) At the bottom
 * 
 * with interceptor and auth token */
import axios from 'axios'
import { stringify } from 'query-string';

const API_URL = process.env.API_URL

export const requestsProvider = {
    async getList(resource, params)  {
        /**
         * can use all filters
         * const { page, perPage } = params.pagination;
         * const { field, order } = params.sort;
         */
        const { page, perPage } = params.pagination;
        
        try {
            const q = stringify({ page, size: perPage });
            const url = `${API_URL}/contact-sales?${q}`;
            const { data } = await axios.get(url);

            return { data: data.contactSales, total: data.totalItems }
        } catch {
            throw e
        }
    },
    async getOne(resource, params) {
        try {
            const url = `${API_URL}/contact-sales/${params.id}`;
            const { data } = await axios.get(url);
            return { data }
        } catch {
            throw e
        }
    },
    async create(resource, params) {
        /**
         * No needs now
         */
    },
    async update(resource, params) {
        /**
         * No needs now
         */
    },
    async delete(resource, params) {
        /**
         * No needs now
         */
    },
    async getMany(resource, params) {
        /**
         * No needs now
         */
    },
    async getManyReference(resource, params) {
        /**
         * No needs now
         */
    },
    async updateMany(resource, params) {
        /**
         * No needs now
         */
    },
    async deleteMany(resource, params) {
        /**
         * No needs now
         */
    },
};