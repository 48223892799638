/** 
 * example data provider with complex requests: 
 * https://marmelab.com/react-admin/DataProviders.html )) At the bottom
 * 
 * with interceptor and auth token */
import axios from 'axios'

const API_URL = process.env.API_URL

export const packageProvider = {
    async getList(resource, params)  {
        /**
         * can use all filters
         * const { page, perPage } = params.pagination;
         * const { field, order } = params.sort;
         */
        try {
            const url = `${API_URL}/${resource}/all`;
            const data = await axios.get(url).then(({ data }) => data);
            return { data, total: Number.MAX_SAFE_INTEGER }
        } catch {
            throw e
        }
    },
    async getOne(resource, params) {
        try {
            const url = `${API_URL}/${resource}/all`;
            let data = await axios.get(url).then(({ data }) => data);
            data = data.find(i => i.id === params.id)
            return { data }
        } catch {
            throw e
        }
    },
    async create(resource, params) {
        try {
            const response = await axios.post(`${API_URL}/${resource}`, params.data)
                .then(({ data }) => ({ data }));
            return response
        } catch {
            throw e
        }
    },
    async update(resource, params) {
        try {
            const response = await axios.patch(`${API_URL}/${resource}/${params.id}`, params.data)
                .then(({ data }) => ({ data }));

            return response
        } catch {
            throw e
        }
    },
    async delete(resource, params) {
        const response = await axios.delete(`${API_URL}/${resource}/${params.id}`);
        return response
    },
    async getMany(resource, params) {
        /**
         * No needs now
         */
    },
    async getManyReference(resource, params) {
        /**
         * No needs now
         */
    },
    async updateMany(resource, params) {
        /**
         * No needs now
         */
    },
    async deleteMany(resource, params) {
        /**
         * No needs now
         */
    },
};