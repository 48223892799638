import React, { Fragment } from "react";
import {
  List, 
  Datagrid,
  DateField,
  TextField,
  ShowButton,
} from "react-admin";

import { Route, useHistory } from 'react-router-dom';
import { Drawer } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { RequestsEdit } from './RequestsEdit'

const useStyles = makeStyles(theme => ({
  drawerPaper: {
      zIndex: 10,
  },
}));

const PurpleTextField = ({ record, source }) => {
  return <span className={`req-module req-module-${record[source]?.toLowerCase()}`}>
    {record[source]?.toLowerCase()}
  </span>
}

export const RequestsList = ({currentList, ...props}) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClose = React.useCallback(() => {
      history.push('/requests');
  }, [history]);

  return (
    <div>
      <Route path="/requests/:id">
          {({ match }) => {
              const isMatch = !!(
                  match &&
                  match.params &&
                  match.params.id !== 'create'
              );

              return (
                  <Fragment>
                    <List
                      {...props}
                      bulkActionButtons={false}
                      actions={null}
                    >
                      <Datagrid rowClick="edit">
                        <TextField sortable={false} label="Customer email" source="email" />
                        <TextField sortable={false} label="Message" source="text" />
                        <DateField sortable={false} label="Created At" source="createdAt" />
                        <PurpleTextField source="status"/>
                        <ShowButton />
                      </Datagrid>
                    </List>
                    <Drawer
                        variant="persistent"
                        open={true}
                        anchor="right"
                        onClose={handleClose}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                    >
                        {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
                        {isMatch ? (
                            <RequestsEdit
                                id={match.params.id}
                                onCancel={handleClose}
                                {...props}
                            />
                        ) : null}
                    </Drawer>
                  </Fragment>
              );
          }}
      </Route>
    </div>
  );
}

