import axios from "axios";
import jwt_decode from "jwt-decode";
import {
  JWT_REFRESH_TOKEN,
  JWT_TOKEN,
  PAREIT_AUTH_SERVICE,
  PERMISSIONS,
  TOKEN_URL,
} from "../constants";
import { setCookie } from "../utils/CookieUtils";
const refreshTokenAxios = axios.create();
const API_URL = process.env.API_URL;
export default () => {
  axios.interceptors.request.use((request) => {
    const token = JSON.parse(sessionStorage.getItem(JWT_TOKEN));
    if (token) {
      request.headers.common["Authorization"] = `Bearer ${token}`;
      const decode = jwt_decode(token);
      let refresh_token = JSON.parse(sessionStorage.getItem(JWT_REFRESH_TOKEN));
      if (Date.now() >= decode.exp * 1000) {
        refreshTokenAxios
          .post(
            `${API_URL}${TOKEN_URL}`,
            { refresh_token },
            {
              headers: {
                "dapr-app-id": PAREIT_AUTH_SERVICE,
              },
            }
          )
          .then((res) => {
            const decode = jwt_decode(res.data.access_token) || {};
            setCookie("access_token", res.data.access_token, {});
            sessionStorage.setItem(
              JWT_TOKEN,
              JSON.stringify(res.data.access_token)
            );
            sessionStorage.setItem(
              JWT_REFRESH_TOKEN,
              JSON.stringify(res.data.refresh_token)
            );
            sessionStorage.setItem(PERMISSIONS, JSON.stringify(decode.role));
            location.assign("/");
          });
      }
    }
    return request;
  });
};
