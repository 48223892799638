import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  Toolbar,
  ListButton,
  useNotify,
  required,
  email,
  SaveButton,
  useRedirect,
  FormDataConsumer,
} from "react-admin";
import { dataProvider } from "../../../provider/dataProvider";
import { includeNumber, includeSpace } from "../../../validators";
import "react-phone-number-input/style.css";
import PhoneInput, {
  formatPhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import {
  ADMIN,
  ADMIN_ADDED,
  INVALID_PHONE_NUMBER,
  REQUIRED,
  SOMETHING_WENT_WRONG,
} from "../../../constants";

export const AdminCreate = ({ permissions, ...props }) => {
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [phoneError, setPhoneError] = React.useState("");
  const notify = useNotify();
  const redirect = useRedirect();

  const handleSubmit = async (formData) => {
    try {
      const data = { ...formData, phone: phoneNumber };
      if (
        phoneNumber &&
        formatPhoneNumber(phoneNumber).trim() !== "" &&
        isValidPhoneNumber(phoneNumber)
      ) {
        const response = await dataProvider.create(ADMIN, data);
        if (!response.data) {
          throw { message: SOMETHING_WENT_WRONG };
        }
        notify(ADMIN_ADDED);
        redirect(`/${ADMIN}/${response?.data?.id}`);
      }
    } catch (error) {
      notify(error.message || SOMETHING_WENT_WRONG, "error");
    }
  };
  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
    setPhoneError("");
  };

  const handlePhoneNumberBlur = () => {
    if (formatPhoneNumber(phoneNumber).trim() === "") {
      setPhoneError(REQUIRED);
    }
    if (
      formatPhoneNumber(phoneNumber).trim() !== "" &&
      !isValidPhoneNumber(phoneNumber)
    ) {
      setPhoneError(INVALID_PHONE_NUMBER);
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    if (formatPhoneNumber(phoneNumber).trim() === "") {
      setPhoneError(REQUIRED);
    }
  };
  const MyToolBar = (props) => (
    <Toolbar className="custom-toolbar" {...props}>
      <SaveButton
        onSave={handleSubmit}
        className="btn"
        label="Create Admin"
        redirect="show"
        onClick={handleClick}
        submitOnEnter
      />
      <ListButton icon={null} label="Back" className="pull-right" />
    </Toolbar>
  );

  return (
    <Create {...props}>
      <SimpleForm className="custom-form" toolbar={<MyToolBar />}>
        <FormDataConsumer>
          {({ formData }) => {
            return (
              <>
                <h2>Create admin</h2>
                <div className="form-group">
                  <TextInput
                    validate={[required(), includeNumber(), includeSpace()]}
                    className="custom-input"
                    source="first_name"
                  />
                  <TextInput
                    validate={[required(), includeNumber(), includeSpace()]}
                    className="custom-input"
                    source="last_name"
                  />
                  <TextInput
                    validate={[required(), email()]}
                    className="custom-input"
                    source="email"
                  />
                  <TextInput
                    validate={required()}
                    className="custom-input"
                    source="company"
                  />
                  <>
                    <PhoneInput
                      international
                      placeholder="000 000 000"
                      name="phone"
                      countryCallingCodeEditable={false}
                      value={phoneNumber}
                      onChange={handlePhoneNumberChange}
                      onBlur={handlePhoneNumberBlur}
                      defaultCountry="US"
                      countries={["CA", "IN", "UA", "US"]}
                      className={phoneError ? "PhoneInput-error" : ""}
                    />

                    {!!phoneError && (
                      <span className="phone-number-error">{phoneError}</span>
                    )}
                  </>
                </div>
              </>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};
