import React, { useCallback, useEffect, useState } from "react";
import {
  SimpleForm,
  TextInput,
  Toolbar,
  SaveButton,
  Edit,
  ListButton,
  email,
  required,
  useRedirect,
  useNotify,
} from "react-admin";
import { Prompt } from "react-router";

import defaultAvatar from "../../../avatar.png";
import { AlertDialog } from "../../global/Confirm";
import { dataProvider } from "../../../provider/dataProvider";
import { PackageTable } from "./PackageTable";
import PhoneInput from "react-phone-number-input";
import { includeNumber, includeSpace } from "../../../validators";
import { FormSpy } from "react-final-form";
import axios from "axios";
import {
  ACTIVATE,
  ACTIVATE_USER,
  ACTIVE,
  BLOCKED,
  DEACTIVATE,
  DEACTIVATE_USER,
  DELETE_USER,
  DELETE_USER_CONFIRMATION,
  DELETE_USER_DESC,
  INACTIVE,
  LEAVE_PAGE_CONFIRMATION,
  PAREIT_AUTH_SERVICE,
  PAREIT_PAYMENT_SERVICE,
  PENDING_VERIFICATION,
  SOMETHING_WENT_WRONG,
  UPDATE,
  USER,
  USER_ACTIVATED,
  USER_DEACTIVATED,
  USER_DELETED,
  USER_UPDATED,
} from "../../../constants";
const API_URL = process.env.API_URL;

export const UserEdit = (props) => {
  const [isOpenDialog, setOpenDialog] = useState(false);
  const [avatar, setAvatar] = useState(false);

  const [initialData, setInitialData] = useState(null);
  const [currentData, setData] = useState(null);

  const [packages, setPackages] = useState([]);
  const [confirmLeaveRoute, setConfirmLeavePage] = useState(false);
  const [typeAlert] = useState({
    title: DELETE_USER_CONFIRMATION,
    description: DELETE_USER_DESC,
    type: "delete",
  });

  const redirect = useRedirect();
  const notify = useNotify();

  useEffect(() => {
    if (currentData && !initialData) {
      setInitialData(currentData);
      axios
        .get(`${API_URL}/v1/user-packages?user_id=${currentData.id}`, {
          headers: {
            "dapr-app-id": PAREIT_PAYMENT_SERVICE,
          },
        })
        .then((res) => {
          const userPackages = res?.data?.cases
            .map((casePackages) =>
              casePackages?.user_packages.map((packageObj) => packageObj)
            )
            .flat();
          setPackages(userPackages);
          axios
            .get(`${API_URL}/v1/users/${currentData.id}/avatar`, {
              headers: {
                "dapr-app-id": PAREIT_AUTH_SERVICE,
              },
            })
            .then((res) => {
              setAvatar(res?.data?.image);
            });
        });
    }
  }, [currentData]);

  const deactiveUser = (e, data) => {
    e.preventDefault();
    dataProvider
      .update(USER, {
        action: DEACTIVATE,
        id: data.record.id,
        previousData: data.record,
      })
      .then((res) => {
        if (res.status !== 204) {
          throw { message: SOMETHING_WENT_WRONG };
        }
        setData({ ...currentData, status: INACTIVE, reason: BLOCKED });
        notify(USER_DEACTIVATED);
      })
      .catch((error) => notify(error.message || SOMETHING_WENT_WRONG, "error"));
  };

  const activeUser = (e, data) => {
    e.preventDefault();
    dataProvider
      .update(USER, {
        action: ACTIVATE,
        id: data.record.id,
        previousData: data.record,
      })
      .then((res) => {
        if (res.status !== 204) {
          throw { message: SOMETHING_WENT_WRONG };
        }
        setData({ ...currentData, status: ACTIVE, reason: null });
        notify(USER_ACTIVATED);
      })
      .catch((error) => notify(error.message || SOMETHING_WENT_WRONG, "error"));
  };

  const deleteUser = () => {
    dataProvider
      .delete(USER, {
        id: currentData.id,
      })
      .then((res) => {
        if (res.status !== 204) {
          throw { message: SOMETHING_WENT_WRONG };
        }
        setOpenDialog(false);
        notify(USER_DELETED);
        redirect("/user");
      })
      .catch((error) => notify(error.message || SOMETHING_WENT_WRONG, "error"));
  };

  useEffect(() => {
    let jsonInitialData = JSON.stringify(initialData);
    let jsonCurrentData = JSON.stringify(currentData);
    if (jsonCurrentData !== jsonInitialData) {
      setConfirmLeavePage(true);
    } else {
      setConfirmLeavePage(false);
    }
  }, [initialData, currentData]);

  const save = useCallback(() => {
    dataProvider
      .update(USER, {
        action: UPDATE,
        id: currentData.id,
        data: currentData,
        previousData: initialData,
      })
      .then((res) => {
        if (res.status !== 204) {
          throw { message: SOMETHING_WENT_WRONG };
        }
        notify(USER_UPDATED);
        setConfirmLeavePage(false);
      })
      .catch((error) => notify(error.message || SOMETHING_WENT_WRONG, "error"));
  }, [currentData]);

  const rightBtn =
    currentData?.status === PENDING_VERIFICATION ? "right-btn" : "";

  const MyToolBar = (props) => (
    <Toolbar className="custom-toolbar" {...props}>
      <SaveButton
        className="btn"
        label="Save CHANGES"
        redirect="show"
        onSave={() => save()}
        submitOnEnter
      />
      {currentData?.status !== PENDING_VERIFICATION ? (
        currentData?.status !== INACTIVE && currentData?.reason !== BLOCKED ? (
          <button
            onClick={(e) => deactiveUser(e, props)}
            className="btn-deactivate pull-right custom-btn"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM11 16H9V8H11V16ZM15 16H13V8H15V16Z"
                fill="#FEBF1E"
              />
            </svg>
            {DEACTIVATE_USER}
          </button>
        ) : (
          <button
            onClick={(e) => activeUser(e, props)}
            className="btn-deactivate pull-right custom-btn"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="#FEBF1E"
              width="18px"
              height="18px"
            >
              <path d="M8 5v14l11-7z" />
            </svg>
            {ACTIVATE_USER}
          </button>
        )
      ) : (
        <></>
      )}

      <button
        onClick={() => setOpenDialog(true)}
        className={`btn-delete custom-btn ${rightBtn}`}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM19 4H15.5L14.5 3H9.5L8.5 4H5V6H19V4Z"
            fill="#E32222"
          />
        </svg>
        {DELETE_USER}
      </button>
      <ListButton icon={null} label="Back" className="pull-left" />
    </Toolbar>
  );

  return (
    <>
      <AlertDialog
        type={typeAlert}
        deleteUser={deleteUser}
        closeModal={() => setOpenDialog(false)}
        isOpen={isOpenDialog}
      />
      <div className="admin-user-info">
        <div className="avatar">
          <img
            src={avatar ? `data:image;base64,${avatar}` : defaultAvatar}
            alt="avatar"
          />
        </div>
        <div className="info">
          <h2>
            {currentData?.first_name} {currentData?.last_name}
          </h2>
          <p className="create-ad">
            {new Date(currentData?.created_at).toLocaleDateString()}
          </p>
        </div>
      </div>
      {confirmLeaveRoute ? <Prompt message={LEAVE_PAGE_CONFIRMATION} /> : null}
      <Edit title="User edit" {...props}>
        <SimpleForm toolbar={<MyToolBar />} className="custom-form">
          <h2>Main Information</h2>
          <div className="form-group">
            <TextInput
              validate={[required(), includeNumber(), includeSpace()]}
              className="custom-input"
              source="first_name"
            />
            <TextInput
              validate={[required(), includeNumber(), includeSpace()]}
              className="custom-input"
              source="last_name"
            />
            <TextInput
              validate={[required(), email()]}
              disabled
              className="custom-input"
              label="Email"
              source="contact.email"
            />
            <TextInput
              validate={required()}
              className="custom-input"
              source="company_name"
            />
            <PhoneInput
              value={currentData?.contact?.phone}
              className="edit-input"
              disabled
            />

            <FormSpy {...props} onChange={({ values }) => setData(values)} />
          </div>
          <PackageTable packages={packages} />
        </SimpleForm>
      </Edit>
    </>
  );
};
