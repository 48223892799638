import * as React from 'react';
import axios from "axios";
import {
    useEditController,
    TextField,
    RichTextField,
    SimpleForm,
    DateField,
    useNotify,
    useRefresh
} from 'react-admin';

import { IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

/** ui */
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 40,
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '1em',
    },
    form: {
        [theme.breakpoints.up('xs')]: {
            width: 400,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100vw',
            marginTop: -30,
        },
    }
}));

const ReviewEditToolbar = ({ record, onCancel }) => {
    const notify = useNotify();
    const refresh = useRefresh();

    const [currentStatus, setCurrStatus] = React.useState(null);
    const [statuses] = React.useState([
        'WAITING',
        'IN_PROGRESS',
        'DONE'
    ])

    const save = React.useCallback(async () => {
        await axios.patch(process.env.API_URL + `/contact-sales`, {
            id: record.id,
            status: currentStatus
        });
        notify('Successfully updated!');
        onCancel()
        refresh()
    }, [currentStatus])

    if (!record) return null;
    return (
        <>
            <div style={{ padding: '20px' }}>
                <FormControl style={{minWidth: '200px'}}>
                    <InputLabel id="demo-controlled-open-select-label">Status</InputLabel>
                    <Select defaultValue={record.status} 
                            labelId="demo-controlled-open-select-label" onChange={e => setCurrStatus(e.target.value)}>
                        {statuses.map(status => {
                            return <MenuItem value={status}>{status}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </div>
            <div style={{ padding: '20px' }}>
                <Button variant="contained" color="secondary" onClick={save} disabled={!currentStatus}>Save</Button>
            </div>
        </>
    );
};

export const RequestsEdit = ({ onCancel, ...props }) => {
    const classes = useStyles();
    const controllerProps = useEditController(props);

    if (!controllerProps?.record) {
        return null;
    }

    return (
        <div className={classes.root}>
            <div className={classes.title}>
                <Typography variant="h6">
                    Request Details
                </Typography>
                <IconButton onClick={onCancel}>
                    <CloseIcon />
                </IconButton>
            </div>
            <SimpleForm
                className={classes.form}
                basePath={controllerProps.basePath}
                record={controllerProps.record}
                save={controllerProps.save}
                version={controllerProps.version}
                redirect="list"
                resource="requests"
                toolbar={<ReviewEditToolbar onCancel={onCancel}/>}
            >
              <TextField sortable={false} label="Customer email" source="email" />
              <RichTextField sortable={false} label="Message" source="text" />
              <DateField sortable={false} label="Created At" source="createdAt" />
            </SimpleForm>
        </div>
    );
};