export const Preloader = () => {
    return (
        <div className='preloader'>
            <div className="loader">
                <div className="ball one">
                    <div className="inner"></div>
                </div>
                <div className="ball two">
                    <div className="inner"></div>
                </div>
                <div className="ball three">
                    <div className="inner"></div>
                </div>
                <div className="ball four">
                    <div className="inner"></div>
                </div>
                <div className="ball five">
                    <div className="inner"></div>
                </div>
                <div className="ball six">
                    <div className="inner"></div>
                </div>
                <div className="ball center">
                    <div className="inner"></div>
                </div>
            </div>
        </div>
    )
}
