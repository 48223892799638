import React, { useEffect, useState } from "react";
import {
  List,
  Filter,
  DateInput,
  SearchInput,
  useListContext,
} from "react-admin";

import { useMeasure } from "react-use";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import axios from 'axios'

const Graph = (props) => {
  const [graphData, setgraphData] = useState([]);
  const API_URL = process.env.API_URL;
  const axisNames = {
    y: 'Revenue',
    x: 'Date'
  }

  useEffect(async() => {
    const { filterValues:{date_from, date_to} } = props;

    const dateFrom = date_from ? `&dateFrom=${new Date(date_from).toISOString()}` : `&dateFrom=${new Date(new Date().getTime() - 2592000000).toISOString()}`;
    const dateTo = date_to ? `&dateTo=${new Date(new Date(date_to).getTime() + 86399999).toISOString()}` : `&dateTo=${new Date().toISOString()}`;
   
    try {
      /** Create url for response **/
     const url = `${API_URL}/user-packages/days?${dateFrom}${dateTo}`;
     const response = await axios.get(url)
     const data = []
     response.data.forEach((dataItem, index) => {
       const packages = {}
       dataItem.quantityOfPackageTypes.forEach(packageType => {
         packages[packageType.type] = packageType.quantity
        })
        data.push({
          id: index,
          name: `${dataItem.date.split('-')[2]}.${dataItem.date.split('-')[1]}`,
          ...packages
        })
      })
     /** no paginate data */
     setgraphData(data)     
    } catch(e) {
      throw e
    }
  }, [props.filterValues])
  const [containerRef, { width }] = useMeasure();
  // let { data } = useListContext();
  
  return (<div ref={containerRef}>
    <br />
    <b>{ axisNames.y }<br/></b>
    <br/>
    {width && <><LineChart
        data={Object.values(graphData)}
        height={650}
        width={width}
        margin={{ top: 5, right: 30, left: 20, bottom: 25 }}
      >
        <XAxis width={200} dataKey="name" />
        <YAxis/>
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Legend wrapperStyle={{
            paddingTop: "40px"
        }}/>
        {
          [
            {
              key: 'CUSTOM',
              color:'#fea2f8'
            },
            {
              key: 'XS',
              color:'#eea2a8'
            },
            {
              key: 'S',
              color:'#dbb9fe'
            },
            {
              key: 'M',
              color:'#611fd2'
            },
            {
              key: 'L',
              color:'#b781a3'
            },
            {
              key: 'XL',
              color:'#ef05a5d'
            },
            {
              key: 'XL_PLUS',
              color:'#40d7a8'
            },
          ].map(({ key, color }) => {
            return <Line
              key={key}
              type="monotone"
              dataKey={key}
              stroke={color}
              strokeWidth="3"
              activeDot={{ r: 8 }}
            />
          })
        }
      </LineChart>
      <div style={{ textAlign: 'right', marginTop: '-70px' }}>
        <b>{ axisNames.x }<br/></b>
      </div>
    </>
    }
  </div>);
}

const ListActions = (props) =>  (
  <Filter className='custom-filters' {...props}>
    <DateInput className='custom-filters__custom-date-input' alwaysOn source="date_from" />
    <DateInput className='custom-filters__custom-date-input' alwaysOn source="date_to" />
  </Filter>
);

export const Statistic_2 = ({ ...props }) => {
  return (
    <>
      <br/>
      <div className="admin-user-info">
          <h2>Packages / $</h2>
      </div>
      <br/>
      <List
          {...props}
          pagination={false}
          component="div"
          filters={<ListActions />}
      >
          <Graph />
      </List>
    </>
  );
}