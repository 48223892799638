import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import produce from "immer";
import { PERMISSIONS } from "../../constants";

const menuData = [
  {
    label: "Users Management",
    role: ["Admin", "SuperAdmin"],
    link: "",
    children: [
      {
        label: "Super Admins",
        link: "/super_admin",
        role: ["SuperAdmin"],
      },
      {
        label: "Admins",
        link: "/admin",
        role: ["SuperAdmin"],
      },
      {
        label: "Users",
        link: "/user",
        role: ["Admin", "SuperAdmin"],
      },
    ],
  },
  // {
  //   label: "Plans Administration",
  //   link: "/packages",
  //   role: ["SuperAdmin"],
  //   icon: () => (
  //     <svg
  //       className="menu-icon"
  //       width="18"
  //       height="18"
  //       viewBox="0 0 11 18"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         opacity="0.54"
  //         d="M5.80007 7.9C3.53007 7.31 2.80007 6.7 2.80007 5.75C2.80007 4.66 3.81007 3.9 5.50007 3.9C7.28007 3.9 7.94007 4.75 8.00007 6H10.2101C10.1401 4.28 9.09007 2.7 7.00007 2.19V0H4.00007V2.16C2.06007 2.58 0.500068 3.84 0.500068 5.77C0.500068 8.08 2.41007 9.23 5.20007 9.9C7.70007 10.5 8.20007 11.38 8.20007 12.31C8.20007 13 7.71007 14.1 5.50007 14.1C3.44007 14.1 2.63007 13.18 2.52007 12H0.320068C0.440068 14.19 2.08007 15.42 4.00007 15.83V18H7.00007V15.85C8.95007 15.48 10.5001 14.35 10.5001 12.3C10.5001 9.46 8.07007 8.49 5.80007 7.9Z"
  //         fill="black"
  //       />
  //     </svg>
  //   ),
  // },
  // {
  //   label: "Plans Requests",
  //   link: "/requests",
  //   role: ["Admin", "SuperAdmin"],
  //   icon: () => (
  //     <svg
  //       className="menu-icon"
  //       width="18"
  //       height="18"
  //       xmlns="http://www.w3.org/2000/svg"
  //       viewBox="0 0 512 512"
  //     >
  //       <path d="M467,76H45C20.137,76,0,96.262,0,121v270c0,24.885,20.285,45,45,45h422c24.655,0,45-20.03,45-45V121 C512,96.306,491.943,76,467,76z M460.698,106c-9.194,9.145-167.415,166.533-172.878,171.967c-8.5,8.5-19.8,13.18-31.82,13.18 s-23.32-4.681-31.848-13.208C220.478,274.284,64.003,118.634,51.302,106H460.698z M30,384.894V127.125L159.638,256.08L30,384.894z M51.321,406l129.587-128.763l22.059,21.943c14.166,14.166,33,21.967,53.033,21.967c20.033,0,38.867-7.801,53.005-21.939 l22.087-21.971L460.679,406H51.321z M482,384.894L352.362,256.08L482,127.125V384.894z" />
  //     </svg>
  //   ),
  // },
  // {
  //   label: "Statistic",
  //   role: ["Admin", "SuperAdmin"],
  //   children: [
  //     {
  //       label: "Packages / Users",
  //       link: "/statistic-1",
  //       role: ["Admin", "SuperAdmin"],
  //     },
  //     {
  //       label: "Packages / $",
  //       link: "/statistic-2",
  //       role: ["Admin", "SuperAdmin"],
  //     },
  //     {
  //       label: "Bills / Case",
  //       link: "/statistic-3",
  //       role: ["Admin", "SuperAdmin"],
  //     },
  //     {
  //       label: "Packages revenue",
  //       link: "/statistic-4",
  //       role: ["Admin", "SuperAdmin"],
  //     },
  //     {
  //       label: "Google Analytics",
  //       link: "https://analytics.google.com",
  //       role: ["Admin", "SuperAdmin"],
  //     },
  //   ],
  // },
];

export const MyMenu = () => {
  const [role, setRole] = useState("");
  const [menu, setMenu] = React.useState(
    menuData.map((item) => {
      if (item.children) item.isOpen = true;
      return item;
    })
  );

  const handleOpen = React.useCallback((index) => {
    setMenu(
      produce(menu, (draftState) => {
        draftState[index].isOpen = !draftState[index].isOpen;
      })
    );
  });

  useEffect(() => {
    const role = JSON.parse(sessionStorage.getItem(PERMISSIONS));
    setRole(role);
  }, []);

  return (
    <>
      {menu
        .filter((j) => j.role.some((i) => i === role))
        .map((i, index) => {
          return (
            <List
              key={index}
              component="nav"
              aria-labelledby="nested-list-subheader"
              className="global-menu"
            >
              {i.children ? (
                <ListItem button onClick={() => handleOpen(index)}>
                  <ListItemText primary={i.label} />
                  {i.isOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
              ) : (
                <NavLink to={i.link}>
                  <ListItem button>
                    <ListItemText primary={i.label} />
                    {i.icon && <i.icon />}
                  </ListItem>
                </NavLink>
              )}

              <Collapse in={i.isOpen} timeout="auto" unmountOnExit>
                {i.children &&
                  i.children
                    .filter((j) => j.role.some((i) => i === role))
                    .map((j, ind) => {
                      return (
                        <List key={ind} component="div" disablePadding>
                          {j.link?.startsWith("http") ? (
                            <a
                              target="_blank"
                              className="sub-link"
                              href={j.link}
                            >
                              <ListItem button>
                                <ListItemText primary={j.label} />
                              </ListItem>
                            </a>
                          ) : (
                            <NavLink className="sub-link" to={j.link}>
                              <ListItem button>
                                <ListItemText primary={j.label} />
                              </ListItem>
                            </NavLink>
                          )}
                        </List>
                      );
                    })}
              </Collapse>
            </List>
          );
        })}
    </>
  );
};
