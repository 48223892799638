import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { ACTIVE } from "../../../constants";

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#F2F4F6",
    color: "#000",
    fontWeight: "bold",
    fontSize: "14px",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#fff",
    },
  },
}))(TableRow);

export const PackageTable = ({ packages }) => {
  const centToDollarConverter = (amount) => {
    const conversionValue = 0.01;
    return (amount * conversionValue).toFixed(2);
  };
  return (
    <>
      <h2 className="packages-title">Packages ({packages.length})</h2>
      <TableContainer className="custom-table" component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell>Package</StyledTableCell>
              <StyledTableCell align="right">Package limit</StyledTableCell>
              <StyledTableCell align="right">Payment date</StyledTableCell>
              <StyledTableCell align="right">Payment amount</StyledTableCell>
              <StyledTableCell align="right">Package status</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {packages &&
              packages.map((row, i) => {
                let classes = "";
                if (row.status === ACTIVE) {
                  classes = "active status";
                } else {
                  classes = "inactive status";
                }
                return (
                  <StyledTableRow className="custom-style-table" key={row.id}>
                    <StyledTableCell
                      className="custom-td index"
                      component="th"
                      scope="row"
                    >
                      {i + 1}
                    </StyledTableCell>
                    <StyledTableCell
                      className="custom-td name"
                      component="th"
                      scope="row"
                    >
                      {row.package_name}
                    </StyledTableCell>
                    <StyledTableCell
                      className="custom-td"
                      align="right"
                      component="th"
                      scope="row"
                    >
                      {row.pages}
                    </StyledTableCell>
                    <StyledTableCell className="custom-td" align="right">
                      {new Date(row.payment_at).toLocaleDateString()}
                    </StyledTableCell>
                    <StyledTableCell className="custom-td" align="right">
                      ${centToDollarConverter(row.payment_amount)}
                    </StyledTableCell>
                    <StyledTableCell className={classes} align="right">
                      <span>
                        {row.status.charAt(0).toUpperCase() +
                          row.status.slice(1)}
                      </span>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
