/**
 * About getting data
 * using FormSpy -> 
 * https://stackoverflow.com/questions/59788654/show-edited-value-in-aside-in-react-admin
 */


import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Edit,
    SaveButton,
    SimpleForm,
    NumberInput,
    TextInput,
    ListButton,
    Toolbar,
    useNotify,
    useRedirect,
    required,
    number
} from 'react-admin';
import { Typography, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

/** custom */
import { UserTable } from './UsersTable';
import { UsersModal } from './UsersModal';
import { packageProvider } from '../../provider/packageProvider'

/** use redux */
import { FormSpy } from 'react-final-form';

export const PackageEdit = (props) => {
    const notify = useNotify();
    const redirect = useRedirect();

    /** Datas */
    const [initialData, setInitialData] = useState(null);
    const [currentData, setData] = useState(null);

    /** Booleans */
    const [modalOpen, setModalOpen] = useState(false)

    /** Computed */
    const isCustom = useMemo(() => currentData?.type === 'CUSTOM', [currentData])
    const isXlPlus = useMemo(() => currentData?.type === 'XL_PLUS', [currentData])

    useEffect(() => {
        console.log('@@@@ process.env.TEST_ENV: ', process.env.TEST_ENV);

        /** Current data */
        if(currentData && !initialData) setInitialData(currentData)
    }, [currentData])

    const transform = useCallback(data => {
        let type;
        if(!data.type) type = 'CUSTOM'
        else type = data.type
        return { ...data, type }
    }, [])

    const save = useCallback(async () => {
        try {
            await packageProvider.update('packages', {
                id: currentData?.id,
                data: currentData
            })
            notify('Successfully updated!')
            redirect('/packages')
        } catch(e) {
            notify('Oops, something went wrong', 'error')
        }
    }, [currentData])
    
    const MyToolBar = (props) => (
        <Toolbar className='custom-toolbar' {...props}>
            {isCustom && <div className='w100'>
                <Button onClick={() => setModalOpen(true)}><AddIcon/> Add user</Button>
                <br/>
                <br/>

            </div>}
            <SaveButton
                className="btn"
                label="Save package"
                onSave={() => save()}
                submitOnEnter={true}
            />
            <ListButton icon={null} label="Cancel" className="pull-right"/>
        </Toolbar>
    );

    return (
        <>
            <UsersModal 
                initialData={initialData} 
                open={modalOpen}
                emitClose={() => setModalOpen(false)}
                onClose={() => setModalOpen(false)}/>

            <br/>
            <div className="admin-user-info">
                <h2>Edit package "{initialData?.name}"</h2>
            </div>
            
            <Edit title="Package edit" transform={transform} {...props}>
                <SimpleForm toolbar={<MyToolBar />} className="custom-form">
                    <Typography variant="h6" gutterBottom>
                        {
                            isCustom ? 
                                'Main Information' :
                                'Edit default package'
                        }
                    </Typography>
                    <br/>
                    <div className="form-group">
                        <TextInput className="custom-input" validate={required()} label="Package Name" source="name"/>
                        <NumberInput
                          className="custom-input"
                          validate={[required(), number()]}
                          label="Pages limit"
                          source="pageCount"
                          format={v => v ? v : NaN}/>
                        <NumberInput
                          className="custom-input"
                          validate={[required(), number()]}
                          label="Price"
                          source="price"
                          format={v => v ? v : NaN}/>
                        {isXlPlus && 
                          <NumberInput
                            className="custom-input"
                            validate={number()}
                            label="Page price over limit *"
                            source="additionalPagePrice"
                            format={v => v || (typeof v === 'number' && isFinite(v)) ? v : NaN}/>}
                            

                        {/* spy form */}
                        <FormSpy {...props} onChange={({ values }) => setData(values)}/>
                    </div>
                    {isCustom &&
                        <>
                            <UserTable initialData={initialData} {...props}/>
                        </>
                    }
                </SimpleForm>
            </Edit>
        </>
    );
};
