import React, {useEffect} from "react";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

export const CustomSnackbar = ({openSnackBar, type}) =>  {
    const [open, setOpen] = React.useState(openSnackBar);

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    useEffect(() => {
        setOpen(openSnackBar)
    }, [openSnackBar])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    return (
        <div>
            {type && <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={type.severity}>
                    {type.title}
                </Alert>
            </Snackbar> }
        </div>
    );
}