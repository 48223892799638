//User actions
export const ACTIVATE = "activate";
export const DEACTIVATE = "deactivate";
export const UPDATE = "update";

//User roles
export const USER = "user";
export const TOKEN_ADMIN = "Admin";
export const TOKEN_SUPERADMIN = "SuperAdmin";
export const ADMIN = "admin";
export const SUPER_ADMIN = "super_admin";

//Dapr App IDs
export const PAREIT_AUTH_SERVICE = "pareit-auth-service";
export const PAREIT_PAYMENT_SERVICE = "pareit-payment-service";

//Tokens
export const SUB = "sub";
export const ACCESS_TOKEN = "access_token";
export const REFRESH_TOKEN = "refresh_token";
export const TOKEN_INFO = "token_info";
export const JWT_TOKEN = "jwt-token";
export const JWT_REFRESH_TOKEN = "jwt-refresh-token";
export const PERMISSIONS = "permissions";

//Packages
export const PACKAGES = "packages";

//User statuses
export const ACTIVE = "active";
export const INACTIVE = "inactive";
export const PENDING_VERIFICATION = "pending_verification";

//User reasons
export const BLOCKED = "blocked";

//Endpoints
export const LOG_OUT_URL = "/v1/auth/logout";
export const TOKEN_URL = "/v1/auth/token";
export const VERIFY_OTP_URL = "/v1/auth/verify-otp";
export const PACKAGE_URL = "/v1/packages-all";

//Miscellaneous
export const SECOND = "second";
export const SECONDS = "seconds";
export const CONTACT = "contact.";
export const DELETE_ADMIN_CONFIRMATION = "Delete admin confirmation";
export const DELETE_ADMIN_DESC =
  "Are you sure that you want to delete this admin?\n";
export const DELETE_USER_CONFIRMATION = "Delete user confirmation";
export const DELETE_USER_DESC =
  "Are you sure that you want to delete this user?\n";
export const LEAVE_PAGE_CONFIRMATION = "Are you sure you want to leave?";
export const ACTIVATE_USER = "Activate User";
export const DEACTIVATE_USER = "Deactivate User";
export const ACTIVATE_ADMIN = "Activate Admin";
export const DEACTIVATE_ADMIN = "Deactivate Admin";
export const DELETE_USER = "Delete User";
export const DELETE_ADMIN = "Delete Admin";
export const SAVE_EDIT = "Save Edit";
export const IMPERSONATE_USER = "Impersonate user";

//Error messages
export const SOMETHING_WENT_WRONG = "Oops, something went wrong";
export const INVALID_PHONE_NUMBER = "Invalid phone number";
export const INCORRECT_OTP = "Incorrect OTP";
export const REQUIRED = "Required";
export const ACCOUNT_LOCKED = "Your pareIT account was locked";
export const INVALID_EMAIL_OR_PASSWORD = "Invalid email or password";

//Success messages
export const USER_ADDED = "User added successfully";
export const ADMIN_ADDED = "Admin added successfully";
export const USER_UPDATED = "User is updated";
export const ADMIN_UPDATED = "Admin is updated";
export const USER_ACTIVATED = "User is activated";
export const ADMIN_ACTIVATED = "Admin is activated";
export const USER_DEACTIVATED = "User is deactivated";
export const ADMIN_DEACTIVATED = "Admin is deactivated";
export const USER_DELETED = "User is deleted";
export const ADMIN_DELETED = "Admin is deleted";
export const PROFILE_UPDATED = "Profile successfully updated";
