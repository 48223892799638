import axios from "axios";
import { clearCookie, setCookie } from "../utils/CookieUtils";
import jwt_decode from "jwt-decode";
import {
  ACCESS_TOKEN,
  ACCOUNT_LOCKED,
  INCORRECT_OTP,
  JWT_REFRESH_TOKEN,
  JWT_TOKEN,
  LOG_OUT_URL,
  PAREIT_AUTH_SERVICE,
  PERMISSIONS,
  REFRESH_TOKEN,
  TOKEN_ADMIN,
  TOKEN_INFO,
  TOKEN_SUPERADMIN,
  TOKEN_URL,
  VERIFY_OTP_URL,
} from "../constants";
const API_URL = process.env.API_URL;

const authProvider = {
  login: ({ stateToken, smsCode }) => {
    const data = {
      state_token: stateToken,
      communication_channel: ["sms"],
      sms_verification_code: smsCode,
    };
    return axios
      .post(`${API_URL}${VERIFY_OTP_URL}`, data, {
        headers: {
          "Content-Type": "application/json",
          "dapr-app-id": PAREIT_AUTH_SERVICE,
        },
      })
      .then((res) => {
        if (!res?.data?.sms_verified) {
          throw { message: INCORRECT_OTP };
        }
        if (res?.data?.sms_verified && !res?.data?.token_info) {
          throw { message: ACCOUNT_LOCKED };
        }
        const decode = jwt_decode(res.data[TOKEN_INFO][ACCESS_TOKEN]) || {};
        if (
          res.status === 200 &&
          (decode.role === TOKEN_ADMIN || decode.role === TOKEN_SUPERADMIN)
        ) {
          setCookie("access_token", res.data[TOKEN_INFO][ACCESS_TOKEN], {});
          sessionStorage.setItem(
            JWT_TOKEN,
            JSON.stringify(res.data[TOKEN_INFO][ACCESS_TOKEN])
          );
          sessionStorage.setItem(
            JWT_REFRESH_TOKEN,
            JSON.stringify(res.data[TOKEN_INFO][REFRESH_TOKEN])
          );
          sessionStorage.setItem(PERMISSIONS, JSON.stringify(decode.role));
          location.assign("/");
        }
      })
      .catch((e) => {
        console.log(e);
        throw e;
      });
  },
  /** called when the users clicks on the logout button **/
  logout: async () => {
    axios
      .delete(`${API_URL}${LOG_OUT_URL}`, {
        headers: {
          "dapr-app-id": PAREIT_AUTH_SERVICE,
        },
      })
      .then((res) => {
        sessionStorage.removeItem(JWT_TOKEN);
        sessionStorage.removeItem(JWT_REFRESH_TOKEN);
        sessionStorage.removeItem(PERMISSIONS);
        clearCookie("access_token");
        location.assign("/login");
      });
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: async ({ status }) => {
    if (status === 401 || status === 403) {
      let refresh_token = JSON.parse(sessionStorage.getItem(JWT_REFRESH_TOKEN));
      await axios
        .post(
          `${API_URL}${TOKEN_URL}`,
          { refresh_token },
          {
            headers: {
              "dapr-app-id": PAREIT_AUTH_SERVICE,
            },
          }
        )
        .then(async (res) => {
          console.log(res);
        });
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the users navigates to a new location, to check for authentication
  checkAuth: () => {
    const jwtToken = JSON.parse(sessionStorage.getItem(JWT_TOKEN));
    if (jwtToken) {
      const decode = jwt_decode(jwtToken) || {};
      const expires = decode.exp || 0;

      if (new Date().getTime() / 1000 < expires) {
        return Promise.resolve();
      } else {
        return Promise.reject();
      }
    } else {
      return Promise.reject();
    }
  },
  // called when the users navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    const role = JSON.parse(sessionStorage.getItem(PERMISSIONS));
    return role ? Promise.resolve(role) : Promise.reject();
  },
};

export default authProvider;
