import React, { useCallback, useState } from 'react';
import {
  Create,
  SimpleForm,
  SaveButton,
  TextInput,
  NumberInput,
  Toolbar,
  ListButton,
  useRedirect,
  useNotify,
  required,
  number
} from 'react-admin';
import { Typography } from '@material-ui/core';
import { packageProvider } from '../../provider/packageProvider'

/** use redux */
import { FormSpy } from 'react-final-form';

export const PackageCreate = (props) => {
    const redirect = useRedirect();
    const notify = useNotify();

    /** Datas */
    const [currentData, setData] = useState(null);

    const transform = useCallback(data => {
        let type;
        if(!data.type) type = 'CUSTOM'
        else type = data.type
        return { type, ...data }
    }, [])

    const create = useCallback(async (e) => {
        try {
            const { data: { id } } = await packageProvider.create('packages', { data: currentData })
            notify('Successfully created!');
            redirect(`/packages/${id}`)
        } catch(e) {            
            notify('Oops, something went wrong', 'error');
        }
    }, [currentData])

    const MyToolBar = (props) => (
        <Toolbar className='custom-toolbar' {...props}>
            <SaveButton
                className="btn"
                label="Create package"
                onSave={() => create()}
                submitOnEnter={true}
            />
            <ListButton icon={null} label="Cancel" className="pull-right"/>
        </Toolbar>
    );

    return (
      <>
        <br/>
        <div className="admin-user-info">
            <h2>Create custom package</h2>
        </div>
        <Create title="Package create" transform={transform} {...props}>
            <SimpleForm className="custom-form" toolbar={<MyToolBar/>}>
                <Typography variant="h6" gutterBottom>Create custom package</Typography>
                <br/>
                <div className="form-group">
                    <TextInput className="custom-input" validate={required()} label="Package Name" source="name"/>
                    <NumberInput
                      className="custom-input"
                      validate={[required(), number()]}
                      label="Pages limit"
                      source="pageCount"
                      format={v => v ? v : NaN}/>
                    <NumberInput
                      className="custom-input"
                      validate={[required(), number()]}
                      label="Price"
                      source="price"
                      format={v => v ? v : NaN}/>

                    {/* spy form */}
                    <FormSpy {...props} onChange={({ values }) => setData(values)}/>
                </div>
            </SimpleForm>
        </Create>
      </>
    )
};
