import axios from "axios";

const API_URL = process.env.API_URL;
import {
  ACTIVATE,
  CONTACT,
  DEACTIVATE,
  PAREIT_AUTH_SERVICE,
  SUPER_ADMIN,
  UPDATE,
} from "../constants";
export const userProvider = {
  async getList(resource, params) {
    try {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const sortField = field?.includes(CONTACT)
        ? field?.replace(CONTACT, "")
        : field;
      const { q, date_from, date_to } = params.filter;

      const dateFrom = date_from
        ? `&created_start_at=${new Date(date_from).toISOString()}`
        : "";

      const dateTo = () => {
        if (resource !== SUPER_ADMIN) {
          if (date_to) {
            return `&created_end_at=${new Date(
              new Date(date_to).getTime() + 86399999
            ).toISOString()}`;
          } else {
            return `&created_end_at=${new Date().toISOString()}`;
          }
        } else {
          return "";
        }
      };
      const search = q ? `&search=${encodeURIComponent(q)}` : "";
      const url = `${API_URL}/v1/users?role=${resource}&page_number=${page}&page_size=${perPage}&sort_by=${sortField}&sort_order=${order.toLowerCase()}${search}${dateFrom}${dateTo()}`;

      const response = await axios
        .get(url, {
          headers: {
            "dapr-app-id": PAREIT_AUTH_SERVICE,
          },
        })
        .then((res) => {
          const data = {
            data: res?.data?.users,
            total: JSON.parse(res?.headers?.pagination)?.total_count,
          };
          return data;
        });
      return response;
    } catch {
      throw e;
    }
  },
  async getOne(resource, params) {
    try {
      const response = await axios
        .get(`${API_URL}/v1/users/${params.id}`, {
          headers: {
            "dapr-app-id": PAREIT_AUTH_SERVICE,
          },
        })
        .then(({ data }) => ({ data }));
      return response;
    } catch {
      throw e;
    }
  },
  async create(resource, params) {
    try {
      const data = {
        first_name: params.first_name,
        last_name: params.last_name,
        company_name: params.company,
        role: resource,
        contact: {
          phone: params.phone,
          email: params.email,
        },
      };

      const response = await axios.put(`${API_URL}/v1/users`, data, {
        headers: {
          "dapr-app-id": PAREIT_AUTH_SERVICE,
        },
      });
      return { data: response.data };
    } catch (error) {
      return Promise.reject();
    }
  },
  async update(resource, params) {
    switch (params.action) {
      case ACTIVATE:
        try {
          const response = await axios.put(
            `${API_URL}/v1/users/${params.id}/activate`,
            null,
            {
              headers: {
                "dapr-app-id": PAREIT_AUTH_SERVICE,
              },
            }
          );

          return response;
        } catch {
          throw e;
        }
      case DEACTIVATE:
        try {
          const response = await axios.put(
            `${API_URL}/v1/users/${params.id}/deactivate`,
            null,
            {
              headers: {
                "dapr-app-id": PAREIT_AUTH_SERVICE,
              },
            }
          );

          return response;
        } catch {
          throw e;
        }
      case UPDATE:
        try {
          const response = await axios.put(
            `${API_URL}/v1/users/${params.id}`,
            params.data,
            {
              headers: {
                "dapr-app-id": PAREIT_AUTH_SERVICE,
              },
            }
          );

          return response;
        } catch {
          throw e;
        }
      default:
    }
  },
  async delete(resource, params) {
    try {
      const response = await axios.delete(`${API_URL}/v1/users/${params.id}`, {
        headers: {
          "dapr-app-id": PAREIT_AUTH_SERVICE,
        },
      });
      return response;
    } catch {
      throw e;
    }
  },
};
